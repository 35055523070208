.App {
  height: 100%;
  min-height: 105vh;
  margin-top: 15px;
  padding-bottom: 75px;
}

.App a:hover {
  text-decoration: none;
}

.App .card {
  background-color: #423E37;
}

.mainBody {
  margin-bottom: 30rem;
}

.App .navbar {
  border: none;
  background-color: rgba(66, 62, 55, .5);
}

.App .nav-tabs {
  border-bottom: none;
}

.App a:hover {
  color: #C4BBBA;
}

.App .navbar-brand {
  font-family: 'Trade Winds', cursive;
  text-shadow: 1px 1px 2px black, 0 0 5px #C4BBBA, 0 0 5px #423E37;
  font-size: xx-large;
  font-weight: bold;
}

.breadcrumb {
  background-color: transparent;
}

.breadcrumb a {
  color: #A39594;
}

.breadcrumb-item {
  font-size: large;
  font-family: 'Bebas Neue', cursive;
  color: #A39594;
}
.breadcrumb-item.active {
  color: #FFF268 !important;
  text-shadow: 1px 1px 2px black, 0 0 25px blue, 0 0 5px darkblue;
}

.radioactive {
  color: #FFF268;
  text-shadow: 1px 1px 2px black, 0 0 25px blue, 0 0 5px darkblue;
}

.full-length {
  height: 100vh;
}

.some-headspace {
  margin-top: 2rem;
}

.center {
  margin: auto;
  text-align: center;
}
.align-middle {
  margin-top: 30vh;
}

.min-padding {
  padding-bottom: min(10rem);
}

.full-width {
  width: 100%;
}

.zero-padding {
  padding: 0rem;
}

.transparent-bg {
  background-color: transparent;
}

.pale-silver {
  color: #C4BBBA;
}

.black-olive {
  color: #423E37;
}

.spanish-gray {
  color: #A39594;
}

.dark-goldenrod-background {
  background-color: #BA9232;
}

.dark-goldenrod {
  color: #BA9232;
}

.Aladin {
  font-family: 'Aladin', cursive;
}

.Gotu {
  font-family: 'Gotu', sans-serif;
}

.Black-Ops {
  font-family: 'Black Ops One', cursive;
}

.Cormorant-Garamond {
  font-family: 'Cormorant Garamond', serif;
}

.Crimson-Text {
  font-family: 'Crimson Text', serif;
}

.PT-Serif {
  font-family: 'PT Serif', serif;
}

.Abel {
  font-family: 'Abel', sans-serif;
}

.Lexend-Tera {
  font-family: 'Lexend Tera', sans-serif;
}

.progress-bar {
  background: rgb(131,58,180);
  background: linear-gradient(90deg, rgba(131,58,180,1) 0%, rgba(253,29,29,1) 50%, rgba(252,176,69,1) 100%);
  height: 30rem;
}

.termly-cookie-preference-button {
  background: none; 
  /* width: 165px;  */
  /* height: 30px;  */
  /* border-radius: 3px;  */
  border: none; 
  /* font-family: Roboto, Arial;  */
  /* font-size: 10px;  */
  /* color: #5f7d9c;  */
  /* font-weight: 600;  */
  box-sizing: border-box; 
  cursor: pointer; 
  padding: 0; 
  outline: none;
}
