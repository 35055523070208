@media all and (min-width: 480px) {
  .Login {
    padding: 60px 0;
  }

  .Login form {
    margin: 0 auto;
    max-width: 320px;
  }
}

.Login form a {
  margin-bottom: 15px;
  display: block;
  font-size: 14px;
}

.Login input {
  height: auto;
  padding-top: 0rem;
  background: none;
}

.FacebookButton {
  display: block;
  margin-left: auto;
  margin-right: auto;
}