.Notes {
  padding-bottom: min(10rem);
}
.Notes form {
  color: #525C52;
  padding-bottom: 15px;
  }
  .Notes .nav .active a {
    color: #423E37;
  }
  .Notes .nav li>a {
    color: #C4BBBA;
  }
  .Notes .nav li>a:hover {
    color: #423E37;
  }

  .Notes form header {
    font-variant: small-caps;
  }
  
  textarea.Notes {
    height: 100vh;
    font-size: 24px;
    padding-top: 45px;
    line-height: 45px;
    background: repeating-linear-gradient(
      0,
      #423E37,
      #E1FCDF 1px,
      #E1FCDF 45px,
      #E1FCDF 45px
    );
  }

.Notes select.form-control, textarea.form-control, input.form-control {
  height: 100vh;
  font-size: 24px;
  padding-top: 45px;
  line-height: 45px;
  background: repeating-linear-gradient(
    0,
    #423E37,
    #E1FCDF 1px,
    #E1FCDF 45px,
    #E1FCDF 45px
  );
}

.Notes  input#authorId {
  height: auto;
  background: #E1FCDF;
  padding-top: 0rem;
}

.NewNote input#authorId {
  line-height: 5rem;
}

.story-content {
  margin-bottom: 500px;
}