.Library {
    background-color: rgb(13, 19, 7);
    text-align: center;
}  
.Library h1, h2, h3, h4 {
    font-family: 'Acme', sans-serif;
    font-weight: 600;
} 
.Library p {
    color: #999;
}
.Library div {
    margin-bottom: 20px;
}
.Library div a:first-child {
    margin: auto;
}
.Library .Library-tabs {
    box-shadow: 5px 5px 5px gray;
}
.Library .research-tab {
    background-color: rgb(13, 19, 7);
    min-height: 100vh;
}
.Library .art {
    margin-top: 75px;
    z-index: 0;

}
.Library .library-list {
    float: right;
    margin-bottom: 50px;
    max-width: 550px;
    width: 50vw;
}

.Library a {
    text-decoration: none;
}

.Library h2, h3, h4 {
    color: aliceblue;
    text-decoration: none;
}
.Library header {
    color: aliceblue;
    padding-bottom: 25px;
}
.Library .welcome-tab p {
    margin: auto;
    padding-top: 25px;
    max-width: 50vw;
}
.Library h1 {
    color: #FFF268;
    text-shadow: 1px 1px 2px black, 0 0 25px blue, 0 0 5px darkblue;
}
.Library .buttons {
    position: relative;
    float: right;
    margin: auto;
}
.Library .Library-tabs {
    padding-top: 0px;
    min-height: 100vh;
}

.Library .list-group-item {
    border: none;
    background-color: none;
    background-image: linear-gradient(to right, rgba(13, 19, 7 ,1), rgba(66, 62, 55, 1));
    z-index: 1;
}
/* .Library .list-group-item:hover {
    box-shadow: 15px -25px 35px aliceblue;
    transition: all .5s ease-in-out;
    transform: scale(1.1);
    z-index: 2;
} */
.Library .content {
    text-align: justify;
}

#stories-tabs .tab-content {
    background-color: rgb(13, 19, 7);
    min-height: 100vh;
}
#stories-tabs .list-group-item {
    background-color: rgb(13, 19, 7);
    background-image: none;
}