.Home {
  padding-bottom: 75px;
}

.Home .notes {
  padding-bottom: 25px;
}

.Home .page-header {
  border-bottom: none;
}

.Home .nav-tabs {
  border-bottom: none;
}

.Home .notes h1, h2 {
  color: #C4BBBA;
  font-family: 'Acme', sans-serif;
}

.Home .notes h4 {
  /* font-family: 'Crimson Text', serif; */
  color: #C4BBBA;
  font-size: x-large;
  font-weight: 600;
  overflow: hidden;
  line-height: 1.5;
  white-space: nowrap;
  text-overflow: ellipsis;
}
/* .Home p {
  color: #666;
} */
.Home .listGroupItem {
  padding-top: 25px;
  line-height: 45px;
  background: repeating-linear-gradient(
    0,
    #423E37,
    #E1FCDF 1px,
    #E1FCDF 45px,
    #E1FCDF 45px
  );
}
.listGroupItem >h3 {
  color: #423E37;
}

.Home .active a {
  color: #423E37;
}

.Home .notes li a {
  color: #C4BBBA;
}

.Home .notes li a:hover {
  color: #423E37;
}

.Home .notes .active {
  color: #423E37;
}

.Laboratory .card-body {
  padding: 0rem;
}
.Laboratory .card-img-bottom {
  margin-top: 3rem;
}